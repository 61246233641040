<template>
  <div class="grid grid-cols-12 items-center">
    <div class="col-span-2 font-semibold text-sm">Tags:</div>
    <div class="col-span-10 space-x-1 space-y-1">
      <Tag
        v-for="tag in tags"
        :key="tag.id"
        :value="tag.name"
        :style="`background-color: #${tag.color};`"
      />
      <Tag
        class="cursor-pointer"
        rounded
        value="+"
        @click="dialog.open(DialogTagEntity, {
        props: {
          breakpoints:{
            '960px': '75vw',
            '640px': '90vw'
          },
          header: 'Tag entity',
          modal: true,
          style: {
            width: '30vw',
          }
        },
        data: {
          tagIds,
        },
        onClose: onSave
      })"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import type {DynamicDialogCloseOptions} from 'primevue/dynamicdialogoptions'
import DialogTagEntity from '~/components/dialogs/DialogTagEntity.vue'

const dialog = useDialog()
const loading = ref<boolean>(false)
const props = defineProps<{
  call?: Call,
  event?: EventInstance,
  lead?: Lead,
  note?: Note,
  reminder?: Reminder,
}>()

const call = toRef(props, 'call')
const event = toRef(props, 'event')
const lead = toRef(props, 'lead')
const note = toRef(props, 'note')
const reminder = toRef(props, 'reminder')

const tags = computed<TagField[]>(() => {
  let value: TagField[] = []

  if (call.value) value = call.value.tags.map(taggedItem => taggedItem.tag)
  if (event.value) value = event.value.tags.map(taggedItem => taggedItem.tag)
  if (lead.value) value = lead.value.tags.map(taggedItem => taggedItem.tag)
  if (note.value) value = note.value.tags.map(taggedItem => taggedItem.tag)
  if (reminder.value) value = reminder.value.tags.map(taggedItem => taggedItem.tag)

  return value
})
const tagIds = computed<number[]>(() => tags.value.map(tag => tag.id))
const toast = useToast()

const onSave = async (opts: DynamicDialogCloseOptions) => {
  if (!opts || !opts.data) return

  const { $api } = useNuxtApp()

  if (props.call) {
    loading.value = true

    await $api<Call>(`/crm/calls/${props.call.id}/`, {
      method: 'PATCH',
      body: JSON.stringify({
        tags: opts.data.tagIds.map((id: number) => ({ tag: id }))
      })
    })
      .then(response => call.value ? call.value.tags = response.tags : undefined)
      .catch(error => errorsToToast(error, toast))
      .finally(() => loading.value = false)
  } else if (props.event) {
    loading.value = true

    await $api<EventInstance>(`/events/${props.event.id}/`, {
      method: 'PATCH',
      body: JSON.stringify({
        tags: opts.data.tagIds.map((id: number) => ({ tag: id }))
      })
    })
      .then(response => event.value ? event.value.tags = response.tags : undefined)
      .catch(error => errorsToToast(error, toast))
      .finally(() => loading.value = false)
  } else if (props.lead) {
    loading.value = true

    await $api<Lead>(`/crm/leads/${props.lead.id}/`, {
      method: 'PATCH',
      body: JSON.stringify({
        tags: opts.data.tagIds.map((id: number) => ({ tag: id }))
      })
    })
      .then(response => lead.value ? lead.value.tags = response.tags : undefined)
      .catch(error => errorsToToast(error, toast))
      .finally(() => loading.value = false)
  } else if (props.note) {
    loading.value = true

    await $api<Note>(`/notes/${props.note.id}/`, {
      method: 'PATCH',
      body: JSON.stringify({
        tags: opts.data.tagIds.map((id: number) => ({ tag: id }))
      })
    })
      .then(response => note.value ? note.value.tags = response.tags : undefined)
      .catch(error => errorsToToast(error, toast))
      .finally(() => loading.value = false)
  } else if (props.reminder) {
    loading.value = true

    await $api<Reminder>(`/reminders/${props.reminder.id}/`, {
      method: 'PATCH',
      body: JSON.stringify({
        tags: opts.data.tagIds.map((id: number) => ({ tag: id }))
      })
    })
      .then(response => reminder.value ? reminder.value.tags = response.tags : undefined)
      .catch(error => errorsToToast(error, toast))
      .finally(() => loading.value = false)
  } else {
    showError({
      message: "Entity cannot be tagged.",
      statusCode: 400,
    })
  }
}
</script>
